<!--  添加银行卡-->
<template>
    <div class="box">
        <van-nav-bar :title="text1" />
        <div class="content">
            <van-form @submit="onSubmit">
                <van-field v-model="form.nickname" name="nickname" label="持卡人姓名" placeholder="请输入持卡人姓名"
                    :rules="[{ required: true, message: '请填写持卡人姓名' }]" />
                <van-field v-model="form.identity" name="identity" label="身份证号码" placeholder="请输入身份证号码" maxlength="18"
                    :rules="[{ required: true, message: '请填写身份证号码' }, { validator, message: '请输入正确身份证号码' }]" />
                <van-field v-model="form.bank_card_number" type="number" name="bank_card_number" label="储蓄卡账号"
                    placeholder="请输入储蓄卡账号" :rules="[{ required: true, message: '请填写储蓄卡账号' }]" />
                <van-field v-model="form.bank_of_deposit" name="bank_of_deposit" label="开户银行名称" placeholder="请输入开户银行名称"
                    :rules="[{ required: true, message: '请填写开户银行名称' }]" />
                <van-field v-model="form.sub_branch" name="sub_branch" label="开户支行" placeholder="例:中国建设银行郑州市金水路支行"
                    :rules="[{ required: true, message: '请填写开户支行' }]" />
                <van-field v-model="form.mobile" type="number" name="mobile" label="银行预留手机号" placeholder="请输入银行预留手机号"
                    maxlength="11"
                    :rules="[{ required: true, message: '请填写手机号' }, { validator2, message: '请输入正确手机号码' }]" />
                <van-field v-model="form.code" type="number" name="code" label="验证码" placeholder="请输入验证码" maxlength="6">
                    <template #button>
                        <van-button size="mini" round type="info" @click="sendCode()" :disabled="disabled">{{ text }}
                        </van-button>
                    </template>

                </van-field>
                <van-cell center title="设为默认银行卡">
                    <template #right-icon>
                        <van-switch v-model="checked" :disabled="flag" size="20" active-color="#88c0f9"
                            @change="changeSwitch()" />
                    </template>
                </van-cell>
                <div style="margin: 16px;">
                    <van-button v-if="JSON.stringify(this.$route.params) != '{}'" round block type="info"
                        native-type="submit">修改</van-button>
                    <van-button v-else round block type="info" native-type="submit">提交</van-button>

                </div>

            </van-form>


        </div>

    </div>
</template>

<script>
import { send, addCard, cardSave } from '@/untils/distubritionIndex.js'
export default {
    name: '',
    data() {
        return {
            form: {
                nickname: '', //持卡人姓名
                identity: '', //身份证号码
                bank_card_number: '',//银行卡号码
                bank_of_deposit: '',//开户行
                sub_branch: '',//开户支行
                mobile: "",//手机号码
                code: '',//验证码
                type: 2
            },
            text: '获取验证',
            disabled: false,//禁用按钮
            num: 60,
            checked: true,
            flag: false,  //是否可以关闭
            text1: '新增银行卡'
        }

    },
    mounted() {
        if (JSON.stringify(this.$route.params) != '{}') {
            console.log(this.$route.params);
            this.form = this.$route.params.item
            this.text1 = '修改银行卡'
            if (this.$route.params.item.type == 2) {
                this.flag = true
            }
        }

    },
    watch: {
        checked: {
            handler(newV, oldV) {
                console.log(this.checked)
                if (this.checked == true) {
                    this.type = 2
                    console.log(this.type);
                } else {
                    this.type = 1
                }
            },
            immediate: true
        }
    },
    methods: {
        changeSwitch(value) {
            console.log(value, 'val');

        },
        //发送验证码
        sendCode() {
            if (this.form.mobile.trim() == '') {
                return this.$toast('请输入手机号')
            }
            if (this.form.mobile.trim().length != 11) {
                return this.$toast('请输入完整的手机号')
            }
            // 发送请求
            let data = {
                mobile: this.form.mobile
            }
            send({ data }).then(res => {

                if (res.data.code == 1) {
                    this.$toast(res.data.msg)
                }
            })
            this.disabled = true
            let num = 60
            let timer = setInterval(() => {
                this.text = num
                num--
                console.log(num);
                if (num == 0) {
                    clearInterval(timer)
                    this.text = '获取验证'
                    this.disabled = false
                }
            }, 1000)


        },
        onSubmit() {
            let data = {
                nickname: this.form.nickname, //持卡人姓名
                identity: this.form.identity, //身份证号码
                bank_card_number: this.form.bank_card_number,//银行卡号码
                bank_of_deposit: this.form.bank_of_deposit,//开户行
                sub_branch: this.form.sub_branch,//开户支行
                mobile: this.form.mobile,//手机号码
                code: this.form.code,//验证码
                user_id: JSON.parse(sessionStorage.getItem('UserInfo')).id,
                type: this.form.type
            }
            // data = this.form
            // data.user_id= JSON.parse(sessionStorage.getItem('UserInfo')).id,   //所属人id
            console.log(data);
            console.log(this.form);
            if (JSON.stringify(this.$route.params) != '{}') {
                cardSave({ data }).then(res => {
                    if (res.data.code == 0) { this.$toast(res.data.msg) }
                    if (res.data.code == 200) {
                        this.$toast(res.data.msg + '即将跳转')
                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 2000);
                    }
                })


            } else {
                addCard({ data }).then(res => {
                    console.log(res);
                    if (res.data.code == 0) { this.$toast(res.data.msg) }
                    if (res.data.code == 200) {
                        this.$toast(res.data.msg + '即将跳转')
                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 2000);
                    }

                })

            }




        },


        sub() {
            //    console.log(this.form); 

        },
        validator() {
            if (this.form.identity.length != 18) {
                return false
            } else {
                return true
            }
        },
        validator2() {
            if (this.form.mobile.length != 11) {
                return false
            } else {
                return true
            }
        }
    }
};
</script>
<style scoped lang="less">
.content {
    width: 345px;
    // height: 398px;
    margin: 20px auto;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 0px rgba(5, 54, 102, 0.1);
    padding: 20px 15px;
    box-sizing: border-box;

    /deep/ .van-field__label {
        width: 98px;
    }

    /deep/ .van-cell::after {
        width: 100%;

    }

    /deep/ .van-form {
        border-radius: 10px;

    }

    /deep/ .van-cell {
        padding-left: 0;
        padding-right: 0;

        .van-button {
            width: 80px;
        }
    }
}
</style>